/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/dist/css/bootstrap.min.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow: auto;
  height: 100%;
}

.header-title {
  font-size: 20px;
  color: #616161;
}

.comp-top-margin {
  margin-top: 10%
}

button {
  outline: none !important;
}

.in_definition {
  background-color: #f9fbe7;
}

.in_definition:hover {
  background-color: #e1f5fe;
}

.row {
  margin-top: 10px;
}

.hidden {
  display: none !important;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.clear_both {
  clear: both;
}

.more-button {
  position: absolute;
  top: 5px;
  right: 10px;
}

.example-spacer {
  flex: 1 1 auto;
}


.pull-right {
  float: right;
}

.import-spinner {
  float: right;
}

.table-header {
  font-size: 14px;
  color: #616161;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.rawgit.com/google/material-design-icons/a6145e16/iconfont/MaterialIcons-Regular.eot');
  /* For IE6-8 */
  src: url('https://cdn.rawgit.com/google/material-design-icons/a6145e16/iconfont/MaterialIcons-Regular.woff2') format('woff2'),
    url('https://cdn.rawgit.com/google/material-design-icons/a6145e16/iconfont/MaterialIcons-Regular.woff') format('woff'),
    url('https://cdn.rawgit.com/google/material-design-icons/a6145e16/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}


.title-section {
  margin: 2%;
  text-align: center;
}

.data-table {
  width: 95%;
  margin: 10px auto;
}

.progress-complete {
  background-color: #66BB6A !important;
}

.progress-counted {
  background-color: #616161 !important;
}

.progress-expected {
  background-color: #03A9F4 !important;
}

.in-progress {
  background-color: #DC5B32 !important;
}

.mat-row:hover {
  background-color: #e1f5fe;
}

.scroll-bottom {
  overflow: auto;
  position: relative;
  height: 100%;
}

.filters {
  width: 100%;
}

.left_filter {
  float: left;
}

.right_filter {
  float: right;
  padding-top: 38px;
}

.small_filter {
  width: 120px;
  padding-right: 10px;
}

.medium_filter {
  width: 200px;
  padding-right: 10px;
}


.large_filter {
  width: 270px;
  padding-right: 10px;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 48%;
}

.tooltipWrapper {
  position: relative;
  display: inline-block;

  /*Hack for old IEs*/
  *position: static;
  *display: inline;
}

.tooltip { pointer-events: none; width: 200px; }

.title-names{
  display: flex;
  justify-content: flex-end;
 }

 .not-synced {
  border-left: 5px solid #a94442;
  margin-bottom: 1px;
}

.save-button {
  background-color: #66BB6A;
  color: white;
}

.serious-button {
  background-color: #a94442;
  color: white;
}

.cancel-button{
  background-color: #616161;
  color: white;
}

.grid-container {
  margin: 20px;
}

.centered {
  margin: auto;
}

:disabled,
input:disabled,
input[disabled],
button:disabled,
button[disabled],
.mat-checkbox-disabled,
.mat-form-field-disabled,
mat-select.mat-select-disabled > div > div > span
 {
  cursor: not-allowed !important;
  color: rgba(0,0,0,0.57)
}

.just-added-text {
  font-size: 20px;
  color: #616161;
  text-align: right;
  padding-right: 2%;
  margin-top: 0.5%;
}